.textIcon {
    cursor: pointer;
}

.cancelIcon {
    color: var(--cancel-color);
    transition: color .2s ease-in-out;
    font-size: 20px;
}

.cancelIcon:hover {
    color: var(--cancel-color-hover);
}

.submitIcon {
    color: var(--adding-color);
    transition: color .2s ease-in-out;
}

.submitIcon:hover {
    color: var(--adding-color-hover);
}