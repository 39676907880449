.container {
    width: 100%;
    font-weight: var(--font-weight);
    color: var(--text-color);
    text-align: left;
}

.notFoundContainer {
    max-width: 800px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px 4px lightgray;
}

.section {
    margin-top: 30px;
    margin-bottom: 30px;
}

.image {
    max-width: 100%;
    height: auto;
    width: auto;
    border-radius: 8px;
}

.carHeading {
    margin-left: 0px;
    padding: 0;
    font-size: 26px;
    line-height: 1;
    font-weight: 400;
}

.listItemText {
    font-size: 20px;
}

.swiper {
    max-width: 500px;
}

.swiperSlide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

.swiperSlide:nth-child(1n) {
    width: fit-content;
    margin-bottom: 10px;
}

.compareIcon {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 26px;
    opacity: 0.4;
    transition: all ease-in-out .2s;
}

.compareIcon:hover {
    opacity: 0.8;
}

.compareIcon.active {
    opacity: 1;
}

.features {
    text-align: left;
    color: var(--text-color);
    font-weight: var(--font-weight);
    line-height: 1.8;
}

.equipment {
    color: var(--text-color);
    line-height: 1.8;
}

.equipmentHeading {
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 400;
}

.equipmentElement {
    text-align: left;
    font-weight: var(--font-weight);
}

.consumption {
    text-align: left;
    color: var(--text-color);
    font-weight: var(--font-weight);
    line-height: 1;
}

.description {
    text-align: left;
    font-weight: var(--font-weight);
    line-height: 1.4;
    color: var(--text-color);
}

.descSection {
    margin: 20px 0;
}

.descHeading {
    font-size: 20px;
    text-transform: uppercase;
    margin: 10px 0;
}

.descText {
    font-size: 16px;
    padding-left: 20px;
}

.callToAction {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.2);
    animation: footSwipeUp 0.5s forwards;
    z-index: 80;
}

/* PRiCE CALC */
.priceCalcWrapper {
    position: relative;
    background-color: var(--light-contrast);
    padding: 20px;
    width: 100%;
    max-width: 400px;
    border-radius: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    color: var(--text-color);
    padding-bottom: 80px;
    margin-bottom: 40px;
    animation: swipeUpBitWrapper 0.5s forwards;
    animation-delay: 0s;
}

.priceCalcHeader {
    font-size: 22px;
    margin-bottom: 20px;
}

.priceCalcHeaderPrice {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
}

.taxLegal {
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    margin: 2px 0;
}

.priceCalcExHeader {
    font-size: 16px;
    font-weight: 600;
}

.priceCalcLine {
    width: 100%;
    border-bottom: 1px solid white;
    padding: 8px 0;
    font-size: 14px;
}
.priceCalcLine > span:last-child {
    float: right;
    font-weight: 600;
}

.priceCalcResultWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    width: fit-content;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    animation: swipeUpBit 1s forwards;
    animation-delay: 0.6s;
    opacity: 0;
}

.priceCalcResultPanel {
    padding: 18px;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 150px;
}

.priceCalcResultPanel:first-child {
    border-right: 1px solid white;
}

.priceCalcResultHighlight {
    font-size: 22px;
    font-weight: 500;
}

.submitButton {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 800px) {
    .smallButtonText {
        display: none
    }
    .upperButtonGroup {
        justify-content: center;
    }
  }
@media screen and (max-width: 540px) {
    .middleButtonText {
        display: block
    }

    .smallBottomButton {
        display: none
    }
    .upperButtonGroup {
        justify-content: center;
    }
  }
@media screen and (max-width: 500px) {
    .priceCalcResultHighlight {
        font-size: 18px;
    }
    .priceCalcResultPanel {
        width: 120px;
        padding: 15px;
    }
    .submitButton {
        justify-content: center;
    }
    .priceCalcWrapper {
        padding-bottom: 60px;
    }
  }
@media screen and (max-width: 400px) {
    .priceCalcResultHighlight {
        font-size: 18px;
    }
    .priceCalcResultExtra {
        font-size: 14px;
    }
    .priceCalcResultPanel {
        padding: 10px;
    }
    .priceCalcWrapper {
        margin-bottom: 40px;
        padding: 10px;
        padding-bottom: 40px;
    }
  }
@media screen and (max-width: 370px) {
    .middleButtonText {
        display: none
    }

    .smallBottomButton {
        display: none
    }
    .priceCalcResultHighlight {
        font-size: 18px;
    }
    .priceCalcResultExtra {
        font-size: 14px;
    }
    .priceCalcResultPanel {
        width: 100px;
        padding: 10px;
    }
    .priceCalcWrapper {
        padding-bottom: 60px;
        margin-bottom: 30px;
    }
  }

@keyframes swipeUpBitWrapper {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes swipeUpBit {
    0% {
        transform: translate(-50%, 20%);
        opacity: 0;
    }
    100% {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}
@keyframes footSwipeUp {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}