.content {
    border-radius: 5px;
}
    
.contentParent {
    padding: 0;
    overflow: hidden;
    transition: max-height ease-out;
}

.collapsible {
  display: flex;
  align-items: center;
}

.collapsible:before {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 13px;
    color: white;
    float: left;
    margin-right: 10px;
    margin-left: 5px;
  }
  
  .active::before {
    content: "\2796"; /* Unicode character for "minus" sign (-) */
  }

  .moveRight {
    margin-left: 30px;
  }