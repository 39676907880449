.bonusCardFormWrapper {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.sectionHeader {
    color: #0D4671;
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 6px;
    font-weight: 300;
    text-align: left;
}

.teamWrapper {
    max-height: 0px;
    height: 100%;
    overflow: hidden;
    transition: all ease-in-out 0.4s;
}

.teamWrapper.expanded {
    max-height: 100%;
}

.carreerOption {
    height: 80px;
    background-color: white;
    border-radius: 8px;
    font-weight: var(--font-weight);
    font-size: 28px;
    margin-left: 10px;
    margin-right: 10px;
    color: var(--text-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgSwiper {
    max-height: 190px;
    height: 100%;
    margin-bottom: 20px;
}

.loyaltyQuestion {
    background-color: transparent;
    border: 1px solid var(--vw-color);
    color: var(--vw-color);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 5px;
    margin-left: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.loyaltyQuestion:hover {
    transform: scale(1.03);
    box-shadow: 0px 0px 4px 1px lightgray;
}

.compInfoContainerHeading {
    font-size: 22px;
    color: var(--text-color);
    margin: 10px 0;
}

.compInfoContainerSubHeading {
    font-size: 20px;
    text-transform: uppercase;
    color: var(--vw-color);
    margin: 25px 0 5px 0;
}

.compInfoContainerText {
    color: var(--text-color);
    font-size: 16px;
    text-align: left;
}

.infoWrapper {
    padding: 20px 0;
}