.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.container {
  border-radius: 4px;
  height: fit-content;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  padding-bottom: 20px;
  margin: 10px;
  overflow: hidden;
  background-color: white;
  cursor: pointer;
}

.container > div > img {
  max-height: 240px;
  /* box-shadow: 0 0 6px 4px lightgray; */
  border-radius: 4px;
}

.jobTitle {
  margin-bottom: auto;
  margin-top: 20px;
  text-align: center;
}

.swiper {
  width: var(--max-content-width);
  height: 100%;
}
  
  .swiperSlide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiperSlide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiperSlide:nth-child(1n) {
  width: fit-content;
  margin: 10px;
}

.newsImage {
  max-width: 300px;
}

.manageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  margin-bottom: 0;
  padding: 5px;
  width: 100px;
  height: 100px;
  font-weight: var(--font-weight);
  cursor: pointer;
  background-color: rgba(0, 255, 0, 0.1);
  border-radius: 8px;
  color: black;
  transition: all 0.2s ease-in-out;
  border: 1px solid green;
}
.addIcon {
  font-size: 40px;
  /* color: var(--adding-color); */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color:white;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  margin-bottom: 5px;
}

.addIcon:hover {
  border: 1px solid rgb(2, 2, 2);
  background-color: green;
  transform: scale(1.02);
}

.cancelIcon {
  color: var(--cancel-color);
  cursor: pointer;
  transition: color .2s ease-in-out;

}

.cancelIcon:hover {
  color: var(--cancel-color-hover);
  transform: scale(1.05);
}

.iconWrapper {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 20px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 40;
  cursor: pointer;
}

.iconWrapper > :nth-child(1n) {
  transition: all 0.2s ease-in-out;
}

.iconWrapper > :nth-child(1n):hover {
  scale: 1.1;
}

.deleteIcon {
  color: var(--cancel-color);
  background-color: white;
  border-radius: 50%;
  margin: 2.5px;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.deleteIcon:hover {
  color: var(--cancel-color-hover);
  transform: scale(1.05);
}

.editIcon {
  color: var(--manage-color);
  background-color: white;
  width: 25px;
  height: 25px;
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.editIcon:hover {
  color: var(--manage-color-hover);
  transform: scale(1.05);
}

.editIconCrop {
  margin: 2.5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}