.container {
    width: 100%;
    font-weight: var(--font-weight);
    color: var(--text-color);
    text-align: left;
}

.image {
    max-width: 100%;
    height: auto;
    width: auto;
    border-radius: 8px;
    cursor: pointer;
}

.carHeading {
    margin-left: 0px;
    padding: 0;
    font-size: 26px;
    line-height: 1;
    font-weight: 400;
}

.swiperSlide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

.swiperSlide:nth-child(1n) {
    width: fit-content;
    margin-bottom: 10px;
}


.compareIcon {
    position: absolute;
    right: 20px;
    top: 25px;
    font-size: 26px;
    opacity: 0.4;
    transition: all ease-in-out .2s;
}

.compareIcon:hover {
    opacity: 0.8;
}

.compareIcon.active {
    opacity: 1;
}

.swipeUpDiv {
  opacity: 0.4;
  transform: translateX(20%);
  transition: all .3s ease-in-out;
}

.swipeUp {
  opacity: 1;
  transform: translateX(0);
}


.textIcon {
    transition: color .6s ease-in-out;
}

.cancelIcon {
    color: lightcoral;
    cursor: pointer;
    transition: color .2s ease-in-out;
}

.cancelIcon:hover {
    color: red;
}

.inViewDiv {
    opacity: 0.6;
    transform: translateY(20%);
    /* transition: all .3s ease-in-out; */
}

.inView {
    opacity: 1;
    transform: translateY(0);
}

.sortImage {
    max-width: 50px;
    max-height: 40px;
    width: 100%;
    height: 100%;
    margin-right: 10px;
    padding: 5px;
}