.imageClass {
    border-radius: 8px;
    overflow: hidden;
}

.thumbnailClass {
    border-radius: 8px;
    overflow: hidden;
}

.closeIcon {
    position: fixed;
    right: 10px;
    top: 20px;
    z-index: 121;
    width: 40px;
    height:40px;
    cursor: pointer;
    color:var(--text-color);
    transition: all .2s ease-in-out;
}

.closeIcon:hover {
    color: var(--primary-color);
}