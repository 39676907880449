.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 120;
  visibility: none;
  opacity: 0;
  pointer-events: none;
  transition: all .4s ease-in-out;
  padding: 0;
}
.modalWrapper.active {
  display: flex;
  height: 100%;
  opacity: 1;
  visibility: visible;
}

.modal {
  /* position: fixed; */
  /* left: 50%;
  transform: translateX(-50%); */
  max-width: 800px;
  position: relative;
  width: 80%;
  /* height: 100%; */
  background-color: white;
  transition: opacity ease-in-out 0.8s;
  transition: top ease-in-out 0.4s;
  transition: max-height ease-in-out 0.2s;
  transition: max-width ease-in-out 0.2s;
  transition: all .4s ease-in-out;
  opacity: 0;
  z-index: 121;
  visibility: hidden;
  overflow: hidden;
  pointer-events: all;
  padding-top: 20px;
  box-sizing: border-box;
}

.modal.scroll{
  height: 100%;
}

.inner {
  /* padding: 20px; */
  /* position: relative; */
  /* width: calc(100% - 40px); */
  /* height: 100%; */
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0;
}

.modal.active {
  max-height: 80vh;
  opacity: 1;
  visibility: visible;
  /* top: 10vh; */
}

.header {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0px 20px;
}

.closeIcon {
  position: absolute;
  right: 15px;
  top: 15px;
  color: var(--text-color);
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  z-index: 120;
}

.closeIcon:hover{
  color: black;
}

.body {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 20px;
}

.heading {
text-transform: uppercase;
font-size: 26px;
margin: 0;
}

.subheading {
text-transform: uppercase;
font-weight: 400;
font-size: 20px;
margin: 0;
color: var(--text-color);
}

@media screen and (min-width: 601px) {
  .modal {
    border-radius: 8px;
  }
}
@media screen and (max-width: 600px) {
  .modalWrapper {
    align-items: flex-start;
    background-color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 0px;
  }
  .modal {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0px;
  }
  .modal.active {
    max-height: 100%;
    height: 100%;
  }
}