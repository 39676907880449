.inheritInputStyle {
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
    text-decoration: inherit;
    text-align: inherit;
    text-transform: inherit;
    line-height: inherit;
    line-break: inherit;
    margin: inherit;
    padding: 0;
    height: inherit;
}

.textIcon {
    transition: color .6s ease-in-out;
    cursor: pointer;
}

.submitIcon {
    color: var(--adding-color);
    transition: color .2s ease-in-out;
}

.submitIcon:hover {
    color: var(--adding-color-hover);
}

.cancelIcon {
    color: var(--cancel-color);
    transition: color .2s ease-in-out;
}
.cancelIcon.inactive {
    color: var(--text-color);
    transition: color .2s ease-in-out;
}

.cancelIcon:hover {
    color: var(--cancel-color-hover);
}

.editor {
    width: 90%;
}