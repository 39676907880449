.display_none {
    display: 'none'
}

.loader {
	height: 100%;
    width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.car__body {
    animation: shake 0.2s ease-in-out infinite alternate;
}
.car__line {
    transform-origin: center right;
    stroke-dasharray: 22;
    animation: line 0.8s ease-in-out infinite;
    animation-fill-mode: both;
}
		
.car__line__top {
animation-delay: 0s;
}

.car__line__middle {
animation-delay: 0.2s;
}

.car__line__bottom {
animation-delay: 0.4s;
		}

@keyframes shake {
	0% {
		transform: translateY(-1%);
	}
	100% {
		transform: translateY(3%);
	}
}

@keyframes line {
	0% {
		stroke-dashoffset: 22;
	}
	
	25% {
		stroke-dashoffset: 22;
	}
	
	50% {
		stroke-dashoffset: 0;
	}
	
	51% {
		stroke-dashoffset: 0;
	}
	
	80% {
		stroke-dashoffset: -22;
	}
	
	100% {
		stroke-dashoffset: -22;
	}
}