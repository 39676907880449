.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0, 0.6);
  z-index: 100;
  transition: background-color ease-in-out 0.25s;
  pointer-events: none;
  touch-action: none;
}
.backdropOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 101;
  pointer-events: all;
}

.default {
  z-index: -1;
  height: 0;
}