.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.container {
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 0;
    padding: 5px;
    width: 180px;
    font-weight: var(--font-weight);
    cursor: pointer;
}

.widgetImage {
    width: 180px;
    border-radius: 4px;
    box-shadow: 0 0 8px 4px lightgray;
    z-index: 9;
    transition: all ease-in-out .2s;
    cursor: pointer;
}

.widgetImage:hover {
    box-shadow: 0px 0px 4px 2px lightgray;
    transform: scale(1.05);
}

.shortInfo {
    width: inherit;
    overflow: hidden;
    text-align: left;
    margin-top: 8px;
    font-size: 20px;
    z-index: 10;
    line-height: 1.2;
}

.model {
    font-weight: 400;
    margin-right: 4px;
}

.price {
    float: right;
    color: var(--text-color)
}

.modalSwiper {
    width: 300px;
}

.modalSwiperSlide:nth-child(1n) {
    margin-bottom: 10px;
}

.swiper {
    width: calc(100% - 20px);
    padding-right: 10px;
    height: 100%;
}
  
.swiperSlide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

}

.swiperSlide:nth-child(1n) {
    width: fit-content;
}

.modalImage {
    height: 200px;
    width: auto;
    cursor: pointer;
}

.priceInfo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}

.priceCategory {
    width: 33%;
    font-size: 28px;
    background-color: var(--light-contrast);
    color: var(--text-color);
}

.priceCategory:first-child {
    border-radius: 4px 0 0 4px;
}

.priceCategory:last-child {
    border-radius: 0 4px 4px 0;
}

.active {
    color: white;
}

.leftPanel {
    width: 40%;
    display: inline-block;
}

.rightPanel {
    width: 30%;
    display: inline-block;
    text-align: center;
    margin-right: 0;
    margin-left: auto;
}

.moreContainer {
    width: 100%;
}

.shortInfo {
    font-size: 24px;
    color: #707070;
    width: 100%;
    /* background-color: rebeccapurple; */
}

.shortInfo > tbody > tr > td:last-child {
    text-align: right;
}

.button {
    font-size: 24px;
    background-color: var(--light-contrast);
    outline: none;
    border: none;
    border-radius: 8px;
}

.panelContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    float: left;
    text-align: left;
    margin: 10px;
}

.image {
    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: 100%;
    border-radius: 8px;
    /* box-shadow: 0 0 8px 4px lightgray; */
}

.leftPanel {
    margin-right: 10px;
}

.carHeading {
    margin: 4px;
    margin-left: 10px;
    padding: 0;
    font-size: 26px;
    font-weight: 400;
}

.quickInfoIcon {
    font-size: 20px;
    color: var(--text-color);
}

.quickInfoText {
    font-size: 20px;
}

.jurInfo {
    color: var(--text-color);
    font-size: 14px;
    font-weight: var(--font-weight);
    font-style: italic;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.manageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-bottom: 0;
    padding: 2px;
    width: 90px;
    height: 90px;
    font-weight: var(--font-weight);
    cursor: pointer;
    background-color: rgba(0, 255, 0, 0.1);
    border-radius: 8px;
    color: black;
    transition: all 0.2s ease-in-out;
    border: 1px solid green;
}

.manageIcon {
    font-size: 30px;
    margin: 5px;
    transition: all 0.2s ease-in-out;
}

.manageText {
    font-size: 12px;
    margin: 5px;
    text-transform: uppercase;
}

.iconWrapper {
    position: absolute;
    right: -5px;
    top: 2.5px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    z-index: 40;
}

.iconWrapper > :nth-child(1n) {
    transition: all 0.1s ease-in-out;
    background-color: white;
}
.iconWrapper > :nth-child(1n):hover {
    scale: 1.02;
}

.deleteIcon {
    color: var(--cancel-color);
    border-radius: 50%;
    margin: 2.5px;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.deleteIcon:hover {
    transform: scale(1.02);
    color: var(--cancel-color-hover);
}

.textIcon {
    transition: color .6s ease-in-out;
}

.cancelIcon {
    color: var(--cancel-color);
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.cancelIcon:hover {
    color: var(--cancel-color-hover);
}

.addIcon {
    color: var(--adding-color);
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.addIcon:hover {
    color: var(--adding-color-hover);
    transform: scale(1.02);
}

.swipeUpDiv {
    opacity: 0.6;
    transform: translateY(10%);
    transition: all .3s ease-in-out;
}

.swipeUp {
    opacity: 1;
    transform: translateY(0);  
}

@media screen and (max-width: 480px) {
    .wrapper {
        margin-top: 40px;
    }
    .container {
        width: 140px;
    }
    .widgetImage {
        width: 140px;
    }
    .widgetImage:hover {
        transform: none;
        box-shadow: 0 0 8px 4px lightgray;
    }
    .shortInfo {
        font-size: 18px;
    }
}


.sortImage {
    max-width: 80px;
    max-height: 80px;
    object-fit: contain;
    width: 100%;
    height: 100%;
    margin-right: 10px;
    padding: 5px;
    border-radius: 8px;
}