.tabWrapper {
  max-width: var(--max-content-width);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

.tab {
  width: fit-content;
}

.tabBar {
  width: 100%;
}

.sectionHeading {
  font-weight: 300;
  color: var(--text-color);
  font-size: 28px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 40px;
  margin-top: 40px;
}

.paperHeading {
  text-align: left;
  font-weight: 500;
  font-size: 24px;
  color: var(--text-color);
}

.leftText {
  text-align: left;
}

.contactWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.modelWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.classPreviewImage {
  width: 150px;
  border-radius: 8px;
}
.classPreviewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 180px;
  height: 120px;
}

.classPreviewWrapper > span {
  margin-bottom: 0px;
  margin-top: auto;
}

.infoText {
  font-size: 18px;
  color: var(--text-color);
}

@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
.notebookPaper {
  position: relative;
  height: 650px;
  width: 100%;
  max-width: 550px;
  background-color: lightgoldenrodyellow;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 5px 0px #888;
}
.notebookPaper::before {
  content: '';
  position: absolute;
  left: 45px;
  height: 100%;
  width: 2px;
  background: rgba(255, 0, 0, 0.4);
}
.notebookLines {
  margin-top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  background-image: repeating-linear-gradient(
    lightgoldenrodyellow 0px,
    lightgoldenrodyellow 39px,
    steelblue 40px
  );
}
.notebookText {
  position: absolute;
  top: -14px;
  left: 55px;
  bottom: 10px;
  right: 10px;
  line-height: 40px;
  font-family: 'Indie Flower';
  overflow: hidden;
  outline: none;
  text-align: left;
  font-size: 26px;
  color: var(--text-color);
}
.holes {
  position: absolute;
  left: 10px;
  height: 25px;
  width: 25px;
  background: white;
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 0px #888;
}
.holeTop {
  top: 10%;
}
.holeMiddle {
  top: 50%;
}
.holeBottom {
  bottom: 10%;
}

.notebookWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: auto;
}

.notebookHeading {
  text-align: left;
  color: var(--text-color);
  font-size: 26px;
  margin: 0;
  padding: 0;
  position: relative;
  top: 20px;
}

.accordionText {
  text-align: left;
  line-height: 1.5;
}

.checklistWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 180px;
  padding: 10px;
  margin: 0;
  /* background-color: var(--light-contrast); */
  border: 1px solid lightgray;
  border-radius: 8px;
  transition: box-shadow ease-in-out 0.4s;
  cursor: pointer;
}

.checklistWrapper:hover {
  box-shadow: 0px 0px 10px 6px lightgray;
}

.accordion {
  margin-top: 5px;
  margin-bottom: 5px;
}
.accordionExpandIcon {
  transform: rotate(-90deg);
}

.checklistTitle {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--text-color);
  max-width: 80%;
  hyphens: auto;
}

.infoItem {
  height: 100%;
  background-color: var(--light-contrast);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: var(--font-weight);
  color: var(--text-color);
  transition: box-shadow ease-in-out 0.2s;
  padding: 0 20px;
}

.infoItem:hover {
  box-shadow: 0px 0px 5px 0px #888;
}

.infoItemWrapper {
  height: calc(25% - 25px);
  margin: 20px 0;
}

.infoItemContainer {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

.swipeUpDiv {
  opacity: 0.4;
  transform: translateY(20%);
  transition: all 0.3s ease-in-out;
}

.swipeUp {
  opacity: 1;
  transform: translateY(0);
}

/* MIETBEDINGUNEN */
.mietWrapper {
  position: relative;
  height: 500px;
  left: 0;
  padding: 0;
  margin: 0;
}

.mietBackground {
  position: absolute;
  left: 0;
  height: 500px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  object-fit: cover;
  object-position: 0;
  filter: brightness(60%);
}

.mietOverlay {
  position: absolute;
  height: 480px;
  overflow: auto;
  top: 50%;
  left: 50%;
  width: calc(100% - 40px);
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.mietContainer {
  width: 100%;
  padding: 0 20px;
}

/* VIDEO IFRAME */
.videoContainer {
  display: relative;
  height: 400px;
}

.backgroundImageIFrame {
  position: relative;
  left: 0;
  height: 400px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  object-fit: cover;
  object-position: -170px;
}

.iFrameContainer {
  width: 600px;
  right: 10px;
  height: 400px;

  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}

.buttonGroupVert {
  margin-left: 0px;
}

.posIframe {
  height: calc(600px * 0.5625);
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px black;
  margin-left: 0px;
}

/* IFRAME RESPONSIVENESS */
@media only screen and (max-width: 900px) {
  .iFrameContainer {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: 660px) {
  .iFrameContainer {
    width: 500px;
    height: 380px;
  }
  .posIframe {
    height: calc(500px * 0.5625);
  }
  .videoContainer {
    height: 380px;
  }
  .backgroundImageIFrame {
    height: 380px;
  }
}

@media only screen and (max-width: 560px) {
  .iFrameContainer {
    width: 400px;
    height: 380px;
  }
  .posIframe {
    height: calc(400px * 0.5625);
  }
  .videoContainer {
    height: 380px;
  }
  .backgroundImageIFrame {
    height: 380px;
  }
}
@media only screen and (max-width: 460px) {
  .iFrameContainer {
    width: 350px;
    height: 340px;
  }
  .posIframe {
    height: calc(350px * 0.5625);
  }
  .videoContainer {
    height: 340px;
  }
  .backgroundImageIFrame {
    height: 340px;
  }
}
@media only screen and (max-width: 410px) {
  .iFrameContainer {
    width: 300px;
    height: 300px;
  }
  .posIframe {
    height: calc(300px * 0.5625);
  }
  .videoContainer {
    height: 300px;
  }
  .backgroundImageIFrame {
    height: 300px;
  }
}
@media only screen and (max-width: 360px) {
  .iFrameContainer {
    width: 260px;
    height: 300px;
  }
  .posIframe {
    height: calc(260px * 0.5625);
  }
  .videoContainer {
    height: 300px;
  }
  .backgroundImageIFrame {
    height: 300px;
  }
}

/* PAPER RESPONSIVENESS */
@media (max-width: 800px) {
  .notebookPaper {
    height: 450px;
  }
  .notebookPaper::before {
    width: 1px;
    left: 35px;
    height: 100%;
    background: rgba(255, 0, 0, 0.4);
  }
  .notebookLines {
    background-image: repeating-linear-gradient(
      lightgoldenrodyellow 0px,
      lightgoldenrodyellow 34px,
      steelblue 35px
    );
  }

  .notebookText {
    left: 35px;
    line-height: 35px;
    font-size: 24px;
    top: -13px;
  }

  .holes {
    width: 20px;
    height: 20px;
    left: 10px;
  }

  .notebookHeading {
    margin-left: 25px;
    font-size: 24px;
    top: 20px;
  }
}
@media (max-width: 600px) {
  .notebookPaper {
    height: 450px;
  }
  .notebookPaper::before {
    width: 1px;
    left: 35px;
    height: 100%;
    background: rgba(255, 0, 0, 0.4);
  }
  .notebookLines {
    background-image: repeating-linear-gradient(
      lightgoldenrodyellow 0px,
      lightgoldenrodyellow 29px,
      steelblue 30px
    );
  }

  .notebookText {
    left: 30px;
    line-height: 30px;
    font-size: 22px;
    top: -13px;
  }

  .holes {
    width: 20px;
    height: 20px;
    left: 10px;
  }

  .notebookHeading {
    margin-left: 25px;
    font-size: 22px;
    top: 20px;
  }
}

@media (max-width: 480px) {
  .notebookPaper {
    height: 450px;
  }
  .notebookPaper::before {
    width: 1px;
    left: 35px;
    height: 100%;
    background: rgba(255, 0, 0, 0.4);
  }
  .notebookLines {
    background-image: repeating-linear-gradient(
      lightgoldenrodyellow 0px,
      lightgoldenrodyellow 25px,
      steelblue 26px
    );
  }

  .notebookText {
    left: 30px;
    line-height: 26px;
    font-size: 18px;
    top: -13px;
  }

  .holes {
    width: 20px;
    height: 20px;
    left: 10px;
  }

  .notebookHeading {
    margin-left: 25px;
    font-size: 18px;
    top: 20px;
  }
}

@media (max-width: 360px) {
  .notebookPaper {
    height: 450px;
  }
  .notebookPaper::before {
    width: 1px;
    left: 35px;
    height: 100%;
    background: rgba(255, 0, 0, 0.4);
  }
  .notebookLines {
    background-image: repeating-linear-gradient(
      lightgoldenrodyellow 0px,
      lightgoldenrodyellow 21px,
      steelblue 22px
    );
  }

  .notebookText {
    left: 20px;
    line-height: 22px;
    font-size: 18px;
    top: -13px;
  }

  .holes {
    width: 20px;
    height: 20px;
    left: 10px;
  }

  .notebookHeading {
    margin-left: 20px;
    font-size: 18px;
    top: 20px;
  }
}
