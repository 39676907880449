.classPreviewImage {
    width: 150px;
    border-radius: 8px;
    transition: all ease-in-out .2s;
    cursor: pointer;
}

.classPreviewImage:hover {
    transform: scale(1.05);
}
.modalImage {
    /* width: 300px; */
    width: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 8px;
    box-shadow: 0px 0px 8px 4px lightgray;
}

.leftPanel {
    margin-top: 10px;
    margin-bottom: 10px;
}
.rightPanel {
    margin-top: 10px;
    margin-bottom: 10px;
}

.womoHeader {
    color: var(--text-color);
    text-align: left;
    position: relative;
    left: 0;
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.panelWrapper {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.classPreviewWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 120px;
    cursor:pointer
}

.classPreviewWrapper > span {
    margin-bottom: 0px;
    margin-top: auto;
}

.caravanInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.caravanInfoWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.classDescription {
    margin-top: 5px;
    color: var(--text-color);
    font-weight: var(--font-weight);
    font-size: 16px;
}


.iconWrapper {
    position: absolute;
    right: 0.1px;
    transform: translateX(20%);
    top: -15px;
    font-size: 20px;
    display: flex;
    flex-direction: column-reverse;
    z-index: 40;
    cursor: pointer;
  }
  
  .iconWrapper > :nth-child(1n) {
    transition: all 0.2s ease-in-out;
  }
  
  .iconWrapper > :nth-child(1n):hover {
    scale: 1.1;
  }
  
  .deleteIcon {
    color: var(--cancel-color);
    background-color: white;
    border-radius: 50%;
    margin: 2.5px;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
  
  .deleteIcon:hover {
    transform: scale(1.05);
    color: var(--cancel-color-hover);
  }
  
  
  .editIcon {
    color: var(--manage-color);
    background-color: white;
    width: 25px;
    height: 25px;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  
  .editIcon:hover {
    transform: scale(1.05);
    color: var(--manage-color-hover);
  }
  
  .editIconCrop {
    margin: 2.5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tabWrapper {
    max-width: var(--max-content-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.tab {
    width: fit-content;
}

.tabBar {
width: 100%;
}

.sectionHeading {
    font-weight: 300;
    color: var(--text-color);
    font-size: 28px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
}

.section {
    margin-bottom: 40px;
    margin-top: 40px;
}

.paperHeading {
    text-align: left;
    font-weight: 500;
    font-size: 24px;
    color: var(--text-color)
}

.leftText {
    text-align: left;
}

.contactWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.modelWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.classPreviewImage {
    width: 150px;
    border-radius: 8px;
}
.classPreviewWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 180px;
    height: 120px;
}

.classPreviewWrapper > span {
    margin-bottom: 0px;
    margin-top: auto;
}

.infoText {
    font-size: 18px;
    color: var(--text-color)
}

@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
.notebookPaper {
  position: relative;
  height: 650px;
  width: 100%;
  max-width: 550px;
  background-color: lightgoldenrodyellow;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 0px 0px 5px 0px #888;
}
.notebookPaper::before {
  content: '';
  position: absolute;
  left: 45px;
  height: 100%;
  width: 2px;
  background: rgba(255,0,0,0.4);
}
.notebookLines {
  margin-top: 40px;
  height: calc(100% - 40px);
  width: 100%;
  background-image: repeating-linear-gradient(lightgoldenrodyellow 0px, lightgoldenrodyellow 39px, steelblue 40px);
}
.notebookText {
  position: absolute;
  top: -14px;
  left: 55px;
  bottom: 10px;
  right: 10px;
  line-height: 40px;
  font-family: 'Indie Flower';
  overflow: hidden;
  outline: none;
  text-align: left;
  font-size: 26px;
  color: var(--text-color);
}
.holes {
  position: absolute;
  left: 10px;
  height: 25px;
  width: 25px;
  background: white;
  border-radius: 50%;
  box-shadow: inset 0px 0px 4px 0px #888;
}
.holeTop {
  top: 10%;
}
.holeMiddle {
  top: 50%;
}
.holeBottom {
  bottom: 10%;
}

.notebookWrapper {
    width: 100%;
    height: 100%;
    position: relative;
}

.notebookHeading {
    text-align: left;
    color: var(--text-color);
    font-size: 26px;
    margin: 0;
    padding: 0;
    position: relative;
    top: 20px;
}

.accordionText {
  text-align: left;
  line-height: 1.5;
}

.checklistWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 180px;
  padding: 10px;
  margin: 0;
  /* background-color: var(--light-contrast); */
  border: 1px solid lightgray;
  border-radius: 8px;
  transition: box-shadow ease-in-out .4s;
  cursor: pointer;
}

.checklistWrapper:hover {
  box-shadow: 0px 0px 10px 6px lightgray;
}

.checklistImage {
  width: 60%;
  object-fit: cover;
  margin-bottom: 10px;
}


.accordion {
  margin-top: 5px;
  margin-bottom: 5px;
}
.accordionExpandIcon {
  transform: rotate(-90deg);
}

.checklistTitle {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--text-color);
  max-width: 80%;
  hyphens: auto;
}

.infoItem {
  height: 100%;
  background-color: var(--light-contrast);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: var(--font-weight);
  color: var(--text-color);
  transition: box-shadow ease-in-out .2s;
}

.infoItem:hover {
  box-shadow: 0px 0px 5px 0px #888;
}

.infoItemWrapper {
  height: 25%;
}

.infoItemContainer {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
}

.swipeUpDiv {
  opacity: 0.4;
  transform: translateY(20%);
  transition: all .3s ease-in-out;
}

.swipeUp {
  opacity: 1;
  transform: translateY(0);
}
.buttonGroupVert {
  margin-left: 0px;
}
.posIframe{
  width: 224px;
  height: 126px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px black;
  margin-left: 0px;
  /* position: relative;
  left: 48%;
  transform: translateX(-50%);
  margin-top: 2%;
  margin-left: 20%; */
}
@media only screen and (min-width: 320px){
  .posIframe{
    width: 224px;
    height: 126px;
    margin-left: 120px;
  }
  .buttonGroupVert {
    margin-left: 120px;
  }
}
@media only screen and (min-width: 390px){
  .posIframe{
    width: 224px;
    height: 126px;
    margin-left: 90px;
  }
  .buttonGroupVert {
    margin-left: 90px;
  }
}
@media only screen and (min-width: 460px){
  .posIframe{
    width: 280px;
    height: 158px;
    margin-left: 0px;
  }
  .buttonGroupVert {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 600px){
  .posIframe{
    width: 392px;
    height: 221px;
    margin-left: 40px;
  }
  .buttonGroupVert {
    margin-left: 40px;
  }
}
@media only screen and (min-width: 700px){
  .posIframe{
    width: 392px;
    height: 221px;
    margin-left: 0px;
  }
  .buttonGroupVert {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 768px){
  .posIframe{
    width: 560px;
    height: 315px;
    margin-left: 0px;
  }
  .buttonGroupVert {
    margin-left: 0px;
  }
}

.addIcon {
  font-size: 40px;
  /* color: var(--adding-color); */
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: white;
  border: 1px solid var(--adding-color);
  background-color: var(--adding-color);
  border-radius: 50%;
  margin-right: 20px;
  margin-left: auto;
}

.addIcon:hover {
  color: var(--adding-color);
  background-color: white;
  transform: scale(1.05);
}
.newsImage {
  max-width: 300px;
}

.newsContent img {
  max-width: 100%;
  margin: 0;
}