.teaserText {
  font-weight: 400;
  color: var(--text-color);
  font-size: 24px;
  font-style: italic;
  text-align: center;
}

.wrapper1{
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 80px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 220px; 
  grid-template-areas: 
    "Infos Infos"
    "Anrede Anrede"
    "Vorname Vorname"
    "Nachname Nachname"
    "Strasse Strasse"
    "Hausnummer Hausnummer"
    "PLZ PLZ"
    "Ort Ort"
    "Telefonnummer Telefonnummer"
    "Email Email"
    "Tätigkeit Tätigkeit"
    "Eintrittstermin Eintrittstermin"
    "Nachricht Nachricht";

  justify-items: center; 
  align-items: center; 
 
}

.wrapper2{
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
  gap: 15px 1px; 
  grid-template-areas: 
    "Lebenslauf Lebenslauf"
    "dataLebenslauf dataLebenslauf"
    "Motivation Motivation"
    "dataMotivation dataMotivation"
    "Empfehlung Empfehlung"
    "dataEmpfehlung dataEmpfehlung"
    "Sonstiges Sonstiges"
    "dataSonstiges dataSonstiges"
    "Datenschutz Datenschutz"
    "Einwilligung Einwilligung";

  justify-items: center; 
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
}

.fields{
  width: 290px;
  text-align: left;
}

.bigfield{
  width: 290px;
}

.annotations{
  text-align: left;
  margin-left: 30px;
}

.stripline{
  margin: 20px 0;
  width: 100%
}

.confirmbtn{
  width: 51%;
  margin-top: 4%;
  margin-bottom: 4%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}


@media only screen and (min-width: 630px){
  .wrapper2{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 50px 1px; 
    grid-template-areas: 
      "Lebenslauf dataLebenslauf"
      "Motivation dataMotivation"
      "Empfehlung dataEmpfehlung"
      "Sonstiges dataSonstiges"
      "Datenschutz Einwilligung";
    justify-items: center; 
    align-items: center; 
    margin: 30px;
  }
}

@media only screen and (min-width: 640px){
  .wrapper1{
      display: grid; 
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
      gap: 10px 1px; 
      grid-template-areas: 
        "Infos Anrede"
        "Vorname Nachname"
        "Strasse Hausnummer"
        "PLZ Ort"
        "Telefonnummer Email"
        "Tätigkeit Eintrittstermin"
        "Nachricht Nachricht"
        "Nachricht Nachricht"; 
  
      justify-items: center; 
      align-items: center; 
      margin-top: 30px;
  }
  .stripline{
      margin-top: 3%;
      width: 85%;
  }
  .bigfield{
      width: 610px
  }
} 

@media only screen and (min-width: 800px){
  .wrapper1{
      display: grid; 
      grid-template-columns: 1fr 1fr; 
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
      gap: 10px 1px; 
      grid-template-areas: 
        "Infos Anrede"
        "Vorname Nachname"
        "Strasse Hausnummer"
        "PLZ Ort"
        "Telefonnummer Email"
        "Tätigkeit Eintrittstermin"
        "Nachricht Nachricht"
        "Nachricht Nachricht"; 
  
      justify-items: center; 
      align-items: center; 
      margin-top: 30px;
  }
  
  .stripline{
      margin-top: 2.5%;
      width: 85%;
  } 
}

@media only screen and (min-width: 980px){
  .wrapper2{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 50px 1px; 
    grid-template-areas: 
      "Lebenslauf dataLebenslauf Motivation dataMotivation"
      "Empfehlung dataEmpfehlung Sonstiges dataSonstiges"
      ". Datenschutz Einwilligung .";

    justify-items: center; 
    align-items: center; 
    margin: 30px;
  }
}

@media only screen and (min-width: 1200px){
  .wrapper1{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr; 
    gap: 50px 1px; 
    grid-template-areas: 
      "Infos Anrede Vorname Nachname"
      "Strasse Hausnummer PLZ Ort"
      "Telefonnummer Email Nachricht Nachricht"
      "Tätigkeit Eintrittstermin Nachricht Nachricht";
  
    justify-items: center; 
    align-items: center; 
    margin-top: 30px;
    } 
  
    .bigfield{
        width: 590px
    }
    .stripline{
      margin-top: 2.5%;
      width: 95%;
  }
}

.Infos { grid-area: Infos; }
.Anrede { grid-area: Anrede; }
.Vorname { grid-area: Vorname; }
.Nachname { grid-area: Nachname; }
.Strasse { grid-area: Strasse; }
.Hausnummer { grid-area: Hausnummer; }
.PLZ { grid-area: PLZ; }
.Ort { grid-area: Ort; }
.Tätigkeit { grid-area: Tätigkeit; }
.Eintrittstermin { grid-area: Eintrittstermin; }
.Telefonnummer { grid-area: Telefonnummer; }
.Email { grid-area: Email; }
.Nachricht { grid-area: Nachricht; }
.Lebenslauf { grid-area: Lebenslauf; }
.dataLebenslauf { grid-area: dataLebenslauf; }
.Motivation { grid-area: Motivation; }
.dataMotivation { grid-area: dataMotivation; }
.Empfehlung { grid-area: Empfehlung; }
.dataEmpfehlung { grid-area: dataEmpfehlung; }
.Sonstiges { grid-area: Sonstiges; }
.dataSonstiges { grid-area: dataSonstiges; }
.Datenschutz { grid-area: Datenschutz; }
.Einwilligung { grid-area: Einwilligung; }