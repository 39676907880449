.internalNewsContainer {
    text-align: left;
    padding-left: 10px;
    hyphens: auto;
}

.intNewsTitle {
    font-weight: 600;
    font-size: 14px;
}
.intNewsSubtitle {
    font-weight: 400;
    font-size: 13px;
}