.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 2px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  width: 90px;
  height: 90px;
  position: relative;
  cursor: pointer;
  /* background-color: var(--light-contrast); */
  border: 1px solid lightgray;
}

.container:hover {
  box-shadow: 0px 0px 6px 2px lightgray;
  transform: scale(1.02);
}

.shortCutSpan {
  margin-bottom: 0px;
  margin-top: auto;
  color: black;
  font-weight: var(--font-weight);
  line-height: 1;
  max-width: 80px;
}

.swiper {
  width: var(--max-content-width);
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.swiperSlide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiperSlide:nth-child(1n) {
  width: fit-content;
  margin: 5px;
}

.manageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 2px;
  width: 90px;
  height: 90px;
  font-weight: var(--font-weight);
  cursor: pointer;
  border-radius: 8px;
  color: black;
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 255, 0, 0.1);
  border: 1px solid green;
}

.manageIcon {
  font-size: 30px;
  margin: 5px;
  transition: all 0.2s ease-in-out;
}

.manageText {
  font-size: 12px;
  margin: 5px;
  text-transform: uppercase;
}

.iconWrapper {
  position: absolute;
  right: -10px;
  top: -10px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  z-index: 40;
}

.iconWrapper > :nth-child(1n) {
  transition: all 0.1s ease-in-out;
}
.iconWrapper > :nth-child(1n):hover {
  transform: scale(1.02);
}

.deleteIcon {
  color: red;
  background-color: white;
  border-radius: 50%;
  margin: 2.5px;
  cursor: pointer;
}

.addContainer {
  background-color: var(--light-contrast);
  border-radius: 8px;
  padding: 10px;
}

.addInput {
  margin-top: 5px;
  margin-bottom: 5px;
}

.textIcon {
  transition: color 0.6s ease-in-out;
}

.cancelIcon {
  color: lightcoral;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
}

.cancelIcon:hover {
  color: red;
}

.inViewDiv {
  opacity: 0.6;
  transform: translateY(20%);
  /* transition: all .3s ease-in-out; */
}

.inView {
  opacity: 1;
  transform: translateY(0);
}

.editListIcons {
  white-space: nowrap;
  position: relative;
  right: 0;
  margin-right: 0;
  margin-left: auto;
}

.editListIcons > * {
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.editListEditIcon {
  color: var(--manage-color);
}

.editListCopyIcon {
  color: var(--info-color);
}

.editListCancelIcon {
  color: var(--cancel-color);
}

.editListEditIcon:hover {
  color: var(--manage-color-hover);
}

.editListCopyIcon:hover {
  color: var(--info-color-hover);
}

.editListCancelIcon:hover {
  color: var(--cancel-color-hover);
}

.editListInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.editListWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 440px) {
  .editListIcons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .editListInfo {
    width: 100%;
  }

  .editListWrapper {
    flex-direction: column;
  }
}
