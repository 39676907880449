* {
  --vw-color: #0D4671;
  --vw-nfz-color:#0D4671;
  --skoda-color: #0E3A2F;
  --audi-color: #D10A00;
  --max-content-width: 1200px;
  --text-color: #474747;
  --warning-color: #EDAC2C;
  --danger-color: #E23922;
  --success-color: green;
  --info-color: #0D4671;
  --light-contrast: #F5F4F7;
  --dark-contrast: #dfdfdf;
  --font-weight: 200;
  --ck-border-radius: 8px;

  /* --primary-color: #D10A00; */
  --focus-ring: #D10A00;

  --adding-color: #00FF00;
  --adding-color-hover: #42BD3B;
  --manage-color: #EDAC2C;
  --manage-color-hover: #b7841f;
  --cancel-color: red;
  --cancel-color-hover: #D10A00;
  --error-text-color: #D10A00;

  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;

  /* --bottom-offset: 110px !important; */

}

:root {
  --color-primary: #0D4671; /* Standardfarbe (z. B. Blau) */
}

.vw {
  --color-primary: #0D4671; /* VW-Blau */
}

.vw-nfz {
  --color-primary: #0D4671; /* VW-Nutzfahrzeuge Blau */
}

.skoda {
  --color-primary: #0E3A2F; /* Škoda-Grün */
}

.audi {
  --color-primary: #D10A00; /* Audi-Rot */
}

.text-wrapping {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

/* COOKIE MANAGEMENT TOOL */
.ccm-settings-summoner {
  z-index: 1;
  position: fixed;
  bottom: var(--ccm-bottom);
  left: 32px;
  width: 42px;
  height: 42px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 4px 1px gray;
  animation-name: none !important;
}

.ccm--button-primary {
background-color: green !important;
border: 0px !important;
}

.ccm-settings-summoner--icon, .ccm-settings-summoner--link, .ccm--ctrl-init {
  animation-name: none !important;
}

.ccm-modal {
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0,0,0, 0.6);
  pointer-events: none;
  touch-action: none;
}

.ccm-modal-inner {
  border-radius: 100px;
  pointer-events: all;
}

/* CKEDITOR */
figure.image {
  text-align: center;
}
figure.image.image-style-side {
  text-align: end;
}

/* AUTO UNCLE */
.auws-icon-wrapper > .auws-icon {
  border-radius: 8px !important;
  width: 40px !important;
  height: 40px !important;
  padding: 5px !important;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12) !important;
  background-color: var(--primary-color) !important;
  z-index: 90 !important;
}

.auws-icon-wrapper > .auws-icon > svg {
  fill: #FFFFFF !important;
}

.auws-engagement-widget {
  right: -2.5px !important;
  bottom: var(--au-bottom-offset) !important;
  width: 110px !important;
  z-index: 90 !important;
}

.containerWithControl {
  border-radius: 0px !important;
  width: 110px !important;
  display: flex;
  justify-content: center;
  z-index: 90 !important;
}
.containerWithControl> * {
  border-radius: 0px !important;
  width: 110px !important;
}

.auws-icon-wrapper > .small.label {
  max-width: 100px !important;
  padding: 5px !important;
  text-align: center !important;
  font-size: 10px !important;
  hyphens: manual;
}

/* FULL SCREEN SLIDER */
.image-gallery-thumbnail.active {
  border-radius: 8px;
  overflow: hidden;
  border-color: var(--primary-color) !important;
}
.image-gallery-thumbnail:hover {
  border-radius: 8px;
  overflow: hidden;
  border-color: var(--primary-color) !important;
}
.image-gallery-icon:hover, .image-gallery-svg:hover {
color: var(--primary-color) !important;
}

.App {
  text-align: center;
}

body {
  background-color: white;
  hyphens: auto;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  text-align: left;
  color: var(--text-color);
}

h1 {
  margin: 10px 0;
  font-weight: 600;
  font-size: 28px;
}

h2 {
  font-weight: 400;
  font-size: 24px;
}

h3 {
  font-weight: 200;
  font-size: 20px;
}

h4 {
  font-weight: 200;
  font-size: 18px;
}

h5 {
  font-weight: 200;
  font-size: 16px;
}

p {
  /* font-size: 18px; */
  font-weight: var(--font-weight);
}

ul {
  color: var(--text-color);
  font-weight: var(--font-weight);
  list-style-type: circle;
  /* padding: 6px 0; */
}

ul li {
  position: relative;
  padding-left: 10px;
}

button:active {
  background-color: initial;
}

/* ul li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border: 1px solid var(--text-color);
  background-color: white;
  border-radius: 50%;
} */

a {
  color: var(--vw-color);
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--light-contrast);
  border-radius: 8px;
  color: var(--text-color);
}

table thead th {
  background-color: #f5f5f5;
  color: #333;
  font-weight: bold;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

table tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table tbody tr:last-child td {
  border-bottom: none;
}

table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

table tbody tr:hover {
  background-color: #f1f1f1;
}

blockquote {
  background-color: #f5f5f5;
  border-left: 4px solid var(--text-color);
  padding: 10px;
  margin: 0 0 20px;
}

blockquote p {
  margin: 0;
  padding: 0;
  font-style: italic;
}

blockquote footer {
  margin-top: 10px;
}

blockquote cite {
  font-style: italic;
  color: #777;
}

.pullRight {
  margin-left: auto;
  margin-right: 0px;
  float: right;
}

.removeStyle {
  margin: 0;
  padding: 0;
}

.noSelect {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.pageHeader {
  text-align: left;
  color: var(--text-color);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 24px;
}

.container {
  background-color: white; 
  max-width: var(--max-content-width);
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    /* margin-top: 20px;
    margin-bottom: 20px; */
    padding-left: calc(10px + env(safe-area-inset-left));
    padding-right: calc(10px + env(safe-area-inset-right));
    overflow: hidden;
    padding: 10px;
}

.container-contrast {
  background-color: var(--light-contrast);
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-contrast-inner {
  padding: 10px;
  max-width: var(--max-content-width);
  /* background-color: white; */
  /* position: relative;
  left: 50%;
  transform: translateX(-50%); */
}

.sectionHeading {
  font-weight: 200;
  color: var(--text-color);
  font-size: 28px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

.standardText {
  text-align: left;
  color: var(--text-color);
  font-size: 16px;
}

.subheading {
  text-align: left;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 22px;
}

.textThatNeedsWrapping {
  overflow-wrap: break-word;
    hyphens: auto;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
}

.dropdown-container {
  z-index: 600;
}