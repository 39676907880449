.priceInfo {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  text-align: center;
}
.priceCategory {
  white-space: nowrap;
  width: 33%;
  font-size: 22px;
  font-weight: 200;
  padding: 4px;
  /* border: 1px solid; */
  color: var(--text-color);
  border-right-width: 0;
  border-left-width: 0;
  display: flex;
  flex-direction: column;
}

.priceCategory.active {
  font-weight: 600;
  border-color: transparent;
}

.priceCategory > .small {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 12px;
  width: 100%;
}
