@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ol {
    list-style-type: decimal;
    padding-left: 2rem; /* Standard-Einrückung */
  }
  ul {
    list-style-type: disc;
    padding-left: 2rem; /* Standard-Einrückung */
  }
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: black !important;
  border-width: 1px !important;

}
.MuiOutlinedInput-notchedOutline {
  transition: all 0.3s !important;
  border: 1px solid #ced4da !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
transition: all 0.3s !important;
  border: 1px solid black !important;
}

@layer components {
  .addIcon {
    @apply inline-flex items-center justify-center text-success/80 bg-success/10 rounded-full p-2 cursor-pointer w-[30px] h-[30px] transition-all duration-300 ease-in-out;
  }

  .addIcon:hover, .group:hover .addIcon {
    @apply text-success bg-success/20;
  }

  .adminInfo {
    @apply text-[12px] text-gray-500 font-normal text-left;
  }

  .manageColor {
    @apply text-[var(--manage-color)] hover:text-[var(--manage-color-hover)] cursor-pointer
  }

  .cancelColor {
    @apply text-[var(--cancel-color)] hover:text-[var(--cancel-color-hover)] cursor-pointer
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
