.wrapper {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 0;
  text-align: left;
  color: var(--text-color);
  font-weight: var(--font-weight);
  width: calc(100% - 50px);
  /* padding-top: 30px; */
  position: relative;
  background-color: white;
}

.swiper {
  max-width: 95%;
  padding-bottom: 40px;
  margin-bottom: 20px;
}

.swiperWrapper {
  margin-top: 40px;
}

.swiperSlide:nth-child(1n) {
    width: 400px;
}

.compareIcon {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 26px;
  opacity: 0.4;
  transition: all ease-in-out .2s;
  z-index:20;
  cursor: pointer;
}

.compareIcon:hover {
  transform: scale(1.02);
}
.compareIcon.active {
  opacity: 1;
}

.expansionpanel{
  width: 100%;
  margin-top: 30px;
}

.btn{
  width: 75%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  margin-top: 20px;
}

.iconspace{
  margin-right: 15px;
  margin-left: 2px;
}

.imageSwiper{
  max-height: 320px;
  max-width: calc(100% - 10px);
  width: 380px;
  height: auto;
  width: 100%;
}

.imageSwiperSlide:nth-child(1n) {
  width: fit-content;
  padding-bottom: 10px;
  margin-top: 4px;
}

.image {
  max-height: 400px;
  max-width: 100%;
  height: auto;
  width: 100%;
  border-radius: 8px;
}

@media screen and (max-width: 500px) {
  .swiperSlide:nth-child(1n) {
    width: 500px;
}
.wrapper {
  width: calc(100% - 40px);
}
  .swiperSlide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
@media screen and (max-width: 375px) {
  .swiperSlide:nth-child(1n) {
    width: 320px;
}
.wrapper {
  width: calc(100% - 20px);
}
}