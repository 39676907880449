.selectItem {
  border-radius: 8px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px;
  transition: all ease-in-out 0.2s;
  width: 80%;
}

.selectAppointmentCase {
  height: 60px;
  background-color: var(--light-contrast);
}

.selectAppointmentCase:hover {
  box-shadow: 0px 0px 8px 0px #888;
  transform: scale(1.01);
}

.panelHeading {
  text-align: left;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 24px;
}

.paginationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.4s;
  position: relative;
  left: -100%;
  overflow: hidden;
}

.paginationPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rentListItem {
  width: 100%;
  /* background-color: var(--light-contrast); */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  border-radius: 8px;
  text-align: left;
  color: var(--text-color);
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.rentListItem:hover {
  color: black;
}

.panelContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.appointmentLabel {
  font-weight: var(--font-weight);
  color: var(--text-color);
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.appointmentTextInput {
  width: 100%;
  font-size: 20px;
  color: var(--text-color);
}

.appointmentSelect {
  width: 100%;
  color: var(--text-color);
  text-align: left;
}

.appointmentCheckbox {
  width: 100%;
  text-align: left;
}

.appointmentTextarea {
  width: 100%;
  text-align: left;
}

.sectionHeading {
  font-weight: 300;
  color: var(--text-color);
  font-size: 28px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
}

.appointmentDatepicker {
  width: 100%;
}

.tab {
  width: fit-content;
}

.tabWrapper {
  max-width: var(--max-content-width);
  padding: 10px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f5f4f7;
  /* width: fit-content; */
}

.tabBar {
  width: 100%;
}
.moreInfosTabPanelText {
  text-align: left;
  color: var(--text-color);
  width: 80%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.moreInfosTextPanel {
  text-align: left;
  padding: 10px;
  margin-bottom: 20px;
}

.moreInfosTextPanelText {
  text-align: justify;
  color: var(--text-color);
}

.moreInfosTextPanelText > div > img {
  max-width: 100%;
}

.btnSpace {
  margin: 40px 0px 40px 0px;
}

.editListIcons {
  position: absolute;
  top: 10px;
  right: 5px;
}

.editListIcons > * {
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.editListEditIcon {
  color: var(--manage-color);
}

.editListCopyIcon {
  color: var(--info-color);
}

.editListCancelIcon {
  color: var(--cancel-color);
}

.editListEditIcon:hover {
  color: var(--manage-color-hover);
}

.editListCopyIcon:hover {
  color: var(--info-color-hover);
}

.editListCancelIcon:hover {
  color: var(--cancel-color-hover);
}

.pdfModal {
  width: 100%;
  height: 100%;
}
.pdfModal > object {
  width: 100%;
  height: 100%;
}
