.viewContainer {
    max-width: var(--max-content-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding-left: calc(env(safe-area-inset-left));
    padding-right: calc( env(safe-area-inset-right));
}

.carContainer {
    width: 100%;
    /* justify-content: space-between; */
}

.carModel {
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    position: relative;
}

.carModel > h2 {
    color: #707070;
    font-size: 26px;
    margin: 0;
    padding: 0;
    font-weight: var(--font-weight);
    line-height: 1;
}

.prevImg {
    max-width: 200px;
    height: 120px;
    position: relative;
    object-fit: contain;
}

.tabWrapper {
    max-width: var(--max-content-width);
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.tabBar {
    /* background-color: var(--light-contrast); */
    width: 100%;
}

.imgOverlay {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: fit-content;
    width: fit-content;
    cursor: pointer;
}

.imgOverlay:hover .quickInfo {
    opacity: 1;
}

.quickInfo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width:100%;
    height: 100%;
    background-color: rgba(255,255,255,0.9);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity ease-in-out 0.2s;
    color: var(--text-color);
    font-size: 14px;
    font-weight: var(--font-weight);
}

.stockInfo {
    font-size: 14px;
    color: var(--text-color);
    padding: 0;
    line-height: 1;
}

.stockInfo > .highlighted {
    color: green;
    font-size: 16px;
    width: 40px;
    height: 40px;
}

.modelInfo > tr {
    color: var(--text-color);
    font-weight: var(--font-weight);
}
.modelInfo > tr > td {
    color: var(--text-color);
    font-weight: var(--font-weight);
    padding: 5px;
}

.filterWrapper {
    box-shadow: 0 0 4px 4px lightgray;
    font-weight: var(--font-weight);
    color: var(--text-color);
    border-radius: 8px;
    padding: 10px;
}

.audiContainer {
    max-width: 800px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 10px;
    box-shadow: 0 0 8px 4px lightgray;
}

.quickInfoIcon {
    font-size: 20px;
    color: var(--text-color);
}

.quickInfoText {
    font-size: 16px;
    font-weight: var(--font-weight);
}

.jurInfo {
    color: var(--text-color);
    font-size: 16px;
    font-weight: var(--font-weight);
}

/* 
.iconWrapper {
    position: absolute;
    right: -7.5px;
    top: -7.5px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    z-index: 40;
}
  
.iconWrapper > :nth-child(1n) {
    transition: all 0.1s ease-in-out;
  }
.iconWrapper > :nth-child(1n):hover {
    scale: 1.05;
  }
  
.deleteIcon {
    color: var(--cancel-color);
    background-color: white;
    border-radius: 50%;
    margin: 2.5px;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }

.deleteIcon:hover {
    color: var(--cancel-color-hover);
    scale: 1.05;
} */


.iconWrapper {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    z-index: 40;
    cursor: pointer;
  }
  
  .iconWrapper > :nth-child(1n) {
    transition: all 0.2s ease-in-out;
  }
  
  .iconWrapper > :nth-child(1n):hover {
    scale: 1.1;
  }
  
  .deleteIcon {
    color: var(--cancel-color);
    background-color: white;
    border-radius: 50%;
    margin: 2.5px;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
  
  .deleteIcon:hover {
    transform: scale(1.05);
    color: var(--cancel-color-hover);
  }
  
  
  .editIcon {
    color: var(--manage-color);
    background-color: white;
    width: 25px;
    height: 25px;
    transition: all .2s ease-in-out;
    cursor: pointer;
  }
  
  .editIcon:hover {
    transform: scale(1.05);
    color: var(--manage-color-hover);
  }
  
  .editIconCrop {
    margin: 2.5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.manageIcon {
    margin: 5px;
    color: var(--manage-color);
    width: 40px;
    height: auto;
    position: relative;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.manageIcon:hover {
    color: var(--manage-color-hover);
    scale: 1.05;
}

.addIcon {
    font-size: 40px;
    /* color: var(--adding-color); */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: white;
    border: 1px solid var(--adding-color);
    background-color: var(--adding-color);
    border-radius: 50%;
    margin: 5px;
  }
  
  .addIcon:hover {
    color: var(--adding-color);
    background-color: white;
    transform: scale(1.05);
  }


.carModelManageWrapper { 
 display: flex;
 align-items: center;
 justify-content: center;
}

@media screen and (max-width: 480px) {
    .prevImg {
    max-width: 130px;
    max-height: 140px;
    position: relative;
}
}