.swiper-button-next { color: white}
.swiper-button-prev { color: white}
.swiper-pagination-bullet-active {
    background-color: var(--text-color) !important;
}
.swiper-scrollbar-horizontal {
    background: #d6d6d6;
    cursor: grab;
    margin: 0;
    padding: 0;
}