.container {
    width: 100%;
    font-weight: var(--font-weight);
    color: var(--text-color);
    text-align: left;
}

.image {
    max-width: 100%;
    width: 100%;
    min-height: 150px;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
}

.carHeading {
    margin: 10px 0;
    margin-bottom: 15px;
    padding: 0;
    /* font-size: 26px; */
    line-height: 1;
    font-weight: 400;
    max-width: calc(100% - 50px);
}

.swiper {
    /* width: 400px; */
    width: 100%;
    max-width: 400px;
}

.swiperSlide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

.swiperSlide:nth-child(1n) {
    width: fit-content;
    margin-bottom: 10px;
}


.compareIcon {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 26px;
    opacity: 0.4;
    transition: all ease-in-out .2s;
    cursor: pointer;
}

.compareIcon:hover {
    opacity: 0.8;
}

.compareIcon.active {
    opacity: 1;
}

.swipeUpDiv {
  opacity: 0.4;
  transform: translateX(20%);
  transition: all .3s ease-in-out;
}

.swipeUp {
  opacity: 1;
  transform: translateX(0);
}


.textIcon {
    transition: color .6s ease-in-out;
}

.cancelIcon {
    color: lightcoral;
    cursor: pointer;
    transition: color .2s ease-in-out;
}

.cancelIcon:hover {
    color: red;
}

.inViewDiv {
    opacity: 0.6;
    transform: translateY(20%);
    /* transition: all .3s ease-in-out; */
}

.inView {
    opacity: 1;
    transform: translateY(0);
}