.wrapper {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0 10px; */
  /* padding-bottom: 20px; */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 5px;
  width: 180px;
  font-weight: var(--font-weight);
  text-align: center;
  /* background-color: rebeccapurple; */
  height: 100%;
  /* cursor: pointer; */
}

.name {
  text-transform: uppercase;
  font-size: 18px;
  width: inherit;
  hyphens: manual;
}

.description {
  font-size: 16px;
  color: var(--text-color);
  hyphens: auto;
}

.swiper {
  max-width: var(--max-content-width);
  width: 100%;
  height: 100%;
  padding-left: calc(env(safe-area-inset-left));
  padding-right: calc(env(safe-area-inset-right));
}

.swiperSlide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiperSlide:nth-child(1n) {
  width: fit-content;
  display: flex;
  align-items: flex-start;
  margin: 10px;
}

.contactInfoShort {
  font-size: 14px;
  color: var(--text-color);
  font-weight: var(--font-weight);
  font-style: italic;
  background-color: white;
  padding: 5px 5px;
  border-radius: 8px;
  text-align: center;
  margin-top: auto;
  margin-bottom: 20px;
  max-width: calc(100% - 20px);
}

.editListIcons {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 16px;
  text-align: right;
  cursor: pointer;
}

.editListIcons > * {
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.editListEditIcon {
  color: var(--manage-color);
}

.editListCopyIcon {
  color: var(--info-color);
}

.editListCancelIcon {
  color: var(--cancel-color);
}

.editListEditIcon:hover {
  color: var(--manage-color-hover);
}

.editListCopyIcon:hover {
  color: var(--info-color-hover);
}

.editListCancelIcon:hover {
  color: var(--cancel-color-hover);
}

.lineName {
  color: var(--text-color);
  font-weight: 600;
  font-size: 18px;
}

.lineInfo {
  color: var(--text-color);
  font-weight: var(--font-weight);
}

.lineInfoIcon {
  color: var(--text-color);
  font-weight: var(--font-weight);
  font-size: 16px;
  margin-right: 10px;
}


/* .lineContainer.contacter {
  border: 1px solid var(--vw-color);
}

.lineContainer.active {
  background-color: var(--light-contrast);
} */
